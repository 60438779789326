import axios from "axios";
import React, { Suspense, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";
import Particle from "../components/Particle";
import Socialicons from "../components/Socialicons";
import Spinner from "../components/Spinner";

import Sectiontitle from "../components/Sectiontitle";
import ProgressiveImage from "react-progressive-image";
import FsLightbox from "fslightbox-react";

import Smalltitle from "../components/Smalltitle";
import Resume from "../components/Resume";
import TrackVisibility from "react-on-screen";
import Progress from "../components/Progress";

import PortfoliosView from "../components/PortfoliosView";

import { Link, NavLink } from "react-router-dom";
import { Element } from 'react-scroll'

import Card from 'react-bootstrap/Card';

function Home({ lightMode }) {
  const [information, setInformation] = useState("");

  const [toggler, setToggler] = useState(false);
  const handleToggler = (event) => {
    setToggler(!toggler);
  };

  const [skills, setSkills] = useState([]);
  const [workingExperience, setWorkingExperience] = useState([]);
  const [educationExperience, setEducationExperience] = useState([]);
  const [publications, setPublications] = useState([]);
  const [unpublications, setunPublications] = useState([]);
  const [coursework, setCoursework] = useState([]);

  const [portfolios, setPortfolios] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [portfoliosPerPage] = useState(20);

  useEffect(() => {
    axios.get("/api/information").then((response) => {
      setInformation(response.data);
    });
    axios.get("/api/skills").then((response) => {
      setSkills(response.data);
    });
    axios.get("/api/experience").then((response) => {
      setWorkingExperience(response.data.workingExperience);
      setEducationExperience(response.data.educationExperience);
      setPublications(response.data.publications);
      setunPublications(response.data.unpublications);
      setCoursework(response.data.coursework);
    });
    let mounted = true;
    axios.get("/api/portfolios").then((response) => {
      if (mounted) {
        setPortfolios(response.data);
      }
    });
    return () => (mounted = false);
  }, []);

  const indexOfLastPortfolios = currentPage * portfoliosPerPage;
  const indexOfFirstPortfolios = indexOfLastPortfolios - portfoliosPerPage;
  const currentPortfolios = portfolios.slice(
    indexOfFirstPortfolios,
    indexOfLastPortfolios
  );

  const paginate = (e, pageNumber) => {
    e.preventDefault();
    setCurrentPage(pageNumber);
  };

  return (
    <Layout>
      <Helmet>
        <title>Home - Michael Pérez Portfolio</title>
        <meta
          name="description"
          content="Michael Pérez Portfolio Homepage"
        />
      </Helmet>
      <Suspense fallback={<Spinner />}>
      <Element id='home'></Element>
        <div className="mi-home-area mi-padding-section">
          <Particle lightMode={lightMode} />
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="mi-home-content">
                  <h1>
                    Hi, I'm{" "}
                    <span className="color-theme">{information.name}</span>
                  </h1>
                  <p>{information.aboutContent}</p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="mi-home-content">
                  <div className="mi-header-image">
                    <Link to="/">
                      <ProgressiveImage
                        src={information.brandImage}
                        placeholder="/images/about-image-placeholder.png"
                      >
                        {(src) => <img src={src} alt="brandimage" />}
                      </ProgressiveImage>
                    </Link>
                  </div>
                  <Socialicons bordered />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Element id='about'></Element>
        <div className="mi-home-area mi-padding-section">
          <div className="container">
            <Sectiontitle title="About Me" />
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="mi-about-image">
                  <ProgressiveImage
                    src={information.aboutImage}
                    placeholder="/images/about-image-placeholder.png"
                  >
                    {(src) => (
                      <img
                        src={src}
                        alt="aboutimage"
                        onClick={() => handleToggler(!toggler)}
                      />
                    )}
                  </ProgressiveImage>
                  <FsLightbox
                    toggler={toggler}
                    sources={[information.aboutImageLg]}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mi-about-content">
                  <h3>
                    I am <span className="color-theme">{information.name}</span>
                  </h3>
                  <p>
                  I do research for the Ruiz Human-Computer Interaction Lab and the Data Science Research Lab at UF. I study deep learning and its applications.
                  </p>
                  <ul>
                    {!information.name ? null : (
                      <li>
                        <b>Full Name</b> {information.name}
                      </li>
                    )}
                    {!information.age ? null : (
                      <li>
                        <b>Age</b> {information.age} Years
                      </li>
                    )}
                    {!information.phone ? null : (
                      <li>
                        <b>Phone</b> {information.phone}
                      </li>
                    )}
                    {!information.nationality ? null : (
                      <li>
                        <b>Nationality</b> {information.nationality}
                      </li>
                    )}
                    {!information.language ? null : (
                      <li>
                        <b>Languages</b> {information.language}
                      </li>
                    )}
                    {!information.email ? null : (
                      <li>
                        <b>Email</b> {information.email}
                      </li>
                    )}
                    {!information.address ? null : (
                      <li>
                        <b>Address</b> {information.address}
                      </li>
                    )}
                    {!information.freelanceStatus ? null : (
                      <li>
                        <b>Freelance</b> {information.freelanceStatus}
                      </li>
                    )}
                  </ul>
                  <a href={information.cvfile} target="_blank" className="mi-button">
                    Download CV
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <Element id='resume'></Element>
        <div className="mi-home-area mi-section mi-padding-top mi-padding-bottom">
          <div className="container">
            <Sectiontitle title="Resume" />
            <div className="mt-30"></div>
            <Smalltitle title="Educational Qualifications" icon="book" />
            <div className="mi-resume-wrapper">
              {educationExperience.map((educatonExp) => (
                <Resume key={educatonExp.id} resumeData={educatonExp} />
              ))}
            </div>
            <div className="mt-30"></div>
            <Smalltitle title="Working Experience" icon="briefcase" />
            <div className="mi-resume-wrapper">
              {workingExperience.map((workingExp) => (
                <Resume key={workingExp.id} resumeData={workingExp} />
              ))}
            </div>
            
          <Smalltitle title="Publications/Conferences" icon="paperclip" />
          <div className="mi-resume-wrapper">
            {publications.map((pub) => (
              <Resume key={pub.id} resumeData={pub} />
            ))}
          </div>
          <Smalltitle title="Unpublished Works" icon="paperclip" />
          <div className="mi-resume-wrapper">
            {unpublications.map((pub) => (
              <Resume key={pub.id} resumeData={pub} />
            ))}
          </div>
          <Smalltitle title="Coursework" icon="paperclip" />
          <div className="mi-resume-wrapper">
            {coursework.map((pub) => (
              <Resume key={pub.id} resumeData={pub} />
            ))}
          </div>
          </div>
        </div>
        <div className="mi-home-area mi-section mi-padding-top">
          <div className="container">
            <Sectiontitle title="My Skills" />
            
            <div className="mi-skills">
              <div className="row mt-30-reverse">
              <Card style={{ width: '18rem' }}>
              <Card.Body>
                <Card.Title>Machine Learning/Computer Vision/Natural Language Processing</Card.Title>
                <Card.Text>
                  PyTorch, Tensorflow, OpenCV, scikit-learn
                </Card.Text>
              </Card.Body>
            </Card>
            <Card style={{ width: '18rem' }}>
              <Card.Body>
                <Card.Title>Data Science</Card.Title>
                <Card.Text>
                  NumPy, pandas, SciPy, MATLAB
                </Card.Text>
              </Card.Body>
            </Card>
            <Card style={{ width: '18rem' }}>
              <Card.Body>
                <Card.Title>Computer Graphics and 3D Audio</Card.Title>
                <Card.Text>
                  OpenGL, WebGL, OpenAL
                </Card.Text>
              </Card.Body>
            </Card>
            <Card style={{ width: '18rem' }}>
              <Card.Body>
                <Card.Title>Mobile App Development</Card.Title>
                <Card.Text>
                  Flutter, Android Studio
                </Card.Text>
              </Card.Body>
            </Card>
            <Card style={{ width: '18rem' }}>
              <Card.Body>
                <Card.Title>Web Development</Card.Title>
                <Card.Text>
                  React, Javascript, HTML, CSS, Wordpress
                </Card.Text>
              </Card.Body>
            </Card>
            <Card style={{ width: '18rem' }}>
              <Card.Body>
                <Card.Title>Scientific Writing</Card.Title>
                <Card.Text>
                  LaTeX, Adobe Illustrator, Adobe Photoshop, SigmaPlot
                </Card.Text>
              </Card.Body>
            </Card>
            <Card style={{ width: '18rem' }}>
              <Card.Body>
                <Card.Title>Miscellaneous</Card.Title>
                <Card.Text>
                  Julia, C, C++, C#, Java, F#, Akka.NET, Lex, Yacc, Git, CMake
                </Card.Text>
              </Card.Body>
            </Card>
              </div>
            </div>
          </div>
        </div>
        
        <Element id='portfolio'></Element>
        <div className="mi-home-area mi-section mi-padding-top mi-padding-bottom">
          <div className="container">
            <Sectiontitle title="Portfolio" />
            {<PortfoliosView portfolios={currentPortfolios} />}
            {!(portfolios.length > portfoliosPerPage) ? null : (
              <Pagination
                className="mt-50"
                itemsPerPage={portfoliosPerPage}
                totalItems={portfolios.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            )}
          </div>
        </div>
      </Suspense>
    </Layout>
  );
}

export default Home;
