import Mock from "../mock";

const database = {
  information: {
    name: 'Michael Francis Pérez',
    aboutContent: "I do research for the Ruiz Human-Computer Interaction Lab and the Data Science Research Lab at UF. I study deep learning and its applications.",
    age: 25,
    phone: '',
    nationality: 'American',
    language: 'English, Spanish',
    email: '',
    address: '432 Newell Drive, Gainesville, Florida, USA',
    freelanceStatus: '',
    socialLinks: {
      facebook: null,
      twitter: null,
      pinterest: null,
      behance: null,
      linkedin: 'https://www.linkedin.com/in/michael-perez-8b555a43/',
      dribbble: null,
      github: 'https://github.com/michaelperez023',
      googlescholar: 'https://scholar.google.com/citations?user=fCzyXhUAAAAJ&hl=en'
    },
    brandImage: '/images/headshot.jpg',
    aboutImage: '/images/brooklyn-bridge.jpg',
    aboutImageLg: '/images/brooklyn-bridge.jpg',
    cvfile: '/files/CV.pdf'
  },
  services: [
    {
      title: "Web Design",
      icon: 'brush-alt',
      details: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem tenetur ratione quod."
    }
  ],
  reviews: [
    
  ],
  skills: [
    {
      title: "HTML",
      value: 90
    },
    {
      title: "CSS",
      value: 90
    },
    {
      title: "Javascript",
      value: 80
    },
    {
      title: "ReactJS",
      value: 70
    },
    {
      title: "Photoshop",
      value: 70
    },
    {
      title: "Java",
      value: 80
    },
    {
      title: "C",
      value: 80
    },
    {
      title: "Python",
      value: 90
    },
    {
      title: "C#",
      value: 70
    },
    {
      title: "C++",
      value: 80
    },
    {
      title: "SQL",
      value: 70
    },
    {
      title: "MATLAB",
      value: 60
    },
    {
      title: "Mathematica",
      value: 40
    },
    {
      title: "CLIPS",
      value: 30
    },
    {
      title: "VHDL",
      value: 30
    },
    {
      title: "Mosel (Fico Xpress)",
      value: 40
    },
    {
      title: "Caffe",
      value: 60
    },
    {
      title: "Bison",
      value: 60
    },
    {
      title: "Yacc",
      value: 60
    },
    {
      title: "Julia",
      value: 70
    },
    {
      title: "F#",
      value: 70
    },
    {
      title: "Microsoft Word",
      value: 90
    },
    {
      title: "Microsoft Powerpoint",
      value: 90
    },
    {
      title: "Microsoft Excel",
      value: 90
    },
    {
      title: "Adobe Photoshop",
      value: 70
    },
    {
      title: "Adobe Premiere",
      value: 70
    },
    {
      title: "Adobe Audition",
      value: 70
    },
    {
      title: "Blender",
      value: 70
    },
    {
      title: "Android Studio",
      value: 70
    },
    {
      title: "Microsoft Visual Studio",
      value: 90
    },
    {
      title: "CMake",
      value: 90
    },
    {
      title: "LaTeX",
      value: 90
    },
    {
      title: "OpenCV",
      value: 70
    },
    {
      title: "OpenAL",
      value: 60
    },
    {
      title: "PyTorch",
      value: 80
    },
    {
      title: "Tensorflow",
      value: 80
    },
    {
      title: "Keras",
      value: 70
    },
    {
      title: "Numpy",
      value: 90
    },
    {
      title: "Scipi",
      value: 90
    },
    {
      title: "Scikit-learn",
      value: 80
    },
    {
      title: "Pandas",
      value: 80
    },
    {
      title: "OpenGL",
      value: 80
    },
    {
      title: "WebGL",
      value: 80
    },
    {
      title: "Flutter",
      value: 70
    },
    {
      title: "Akka.NET",
      value: 80
    },
    {
      title: "Wordpress",
      value: 60
    }
  ],
  portfolios: [
    {
      id: 1,
      title: "Mario Graphics Game",
      subtitle: "2.5D Mario Graphics Game developed using WebGL, HTML, and Javascript.",
      imageUrl: "/images/mario-game-square.jpg",
      largeImageUrl: null,
      url: 'https://www.youtube.com/watch?v=5lfsQRtfBVY',
      githubLink: null
    },
    {
      id: 2,
      title: "2D Visual and 3D Audio Experience for Children",
      subtitle: "2D Visual and 3D Audio Experience for children, developed using OpenGL, OpenAL, and GLFW, in Java.",
      imageUrl: "/images/audio-game-square.jpg",
      largeImageUrl: null,
      url: 'https://www.youtube.com/watch?v=ztOUkMKE2qs',
      githubLink: null
    },
    {
      id: 3,
      title: "Twitter Clone",
      subtitle: "Twitter clone developed using the Akka.NET actor model and Suave web framework, in F#.",
      imageUrl: "/images/twitter-clone-square.jpg",
      largeImageUrl: null,
      url: 'https://youtube.com/watch?v=-I0TTJJyyog',
      githubLink: null
    },
    {
      id: 4,
      title: "Advanced Graphics Scene",
      subtitle: "Advanced graphics scene developed using OpenGL's tessellation engine.",
      imageUrl: "/images/graphics-p2-square.jpg",
      largeImageUrl: null,
      url: 'https://www.youtube.com/watch?v=p0I2bMXk2z8',
      githubLink: null
    },
    {
      id: 5,
      title: "Hermes Tracker App",
      subtitle: "Mobile app for tracking mail and packages at Florida Polytechnic University's mail center, developed using Flutter.",
      imageUrl: "/images/hermes-app-square.jpg",
      largeImageUrl: null,
      githubLink: null
    },
    {
      id: 6,
      title: "Fictitious Game Advertisement Video",
      subtitle: "Short video created using Adobe Premiere, which advertises a fictitious video game.",
      imageUrl: "/images/game-ad-square.jpg",
      largeImageUrl: null,
      url: 'https://youtu.be/jsV24C-IH18',
      githubLink: null
    },
    {
      id: 7,
      title: "Dominican Republic Villas Booking Website",
      subtitle: "Dominican Republic villas booking website created using Wordpress.",
      imageUrl: "/images/dominican-go-square.jpg",
      largeImageUrl: null,
      url: 'https://govacationvillas.com',
      githubLink: null
    },
    {
      id: 8,
      title: "Surgery Tool Simulator",
      subtitle: "Surgery simulation graphics scene developed using OpenGL.",
      imageUrl: "/images/simulator-square.jpg",
      largeImageUrl: null,
      url: 'https://youtu.be/r48TvIaX5rI',
      githubLink: null
    },
    {
      id: 9,
      title: "This Portfolio Website",
      subtitle: "Website created from a template in React.js, Javascript, CSS.",
      imageUrl: "/images/portfolio-website-square.jpg",
      largeImageUrl: null,
      url: null,
      githubLink: 'https://github.com/michaelperez023/michaelperez'
    },
    /*{
      id: 10,
      title: "Stylish Meteorologist",
      subtitle: "Alexa Skills and IoT device (raspberry pi connected to a speaker and camera module) that makes sure your outfit is suitable for the current weather conditions. Created over a weekend during a Hackathon with one partner. ",
      imageUrl: "/images/portfolio-website-square.jpg",
      largeImageUrl: null,
      url: null,
      githubLink: 'https://github.com/lePerdu/stylish-meteorologist'
    }*/
  ],
  experience: {
    workingExperience: [
      {
        id: 1,
        year: "2023 - Present",
        position: "Research Assistant",
        company: "Ruiz Human-Computer Interaction and Data Science Research Lab, University of Florida",
        details: "Working on two DARPA-funded research projects with multiple collaborators from other institutions and industry. One involves real-time action recognition and detection in egocentric videos streamed through a Hololens 2 augmented reality headset as part of a perceptual task guidance pipeline. The other project involves question generation and learning from human feedback and interaction with a knowledge-base. "
      },
      {
        id: 2,
        year: "2020 - 2023",
        position: "Research Assistant",
        company: "Graphics, Imaging, and Light Measurement Lab, University of Florida",
        details: "Worked on a research project that leverages adversarial approaches to model 3D motion and behavior from videos for applications in biomedical research. Working under Dr. Corey Toler-Franklin’s direction; Dr. Darragh Devine, from UF’s psychology department, and Dr. Jörg Peters and Dr. Alper Üngör, from UF’s CISE department, will also supervise my research."
      },
      {
        id: 3,
        year: "2020 - 2023",
        position: "Teaching Assistant",
        company: "Computer and Information Science and Engineering Department, University of Florida",
        details: "Graduate Teaching Assistant for numerous classes: Deep Learning for Computer Graphics, Operating Systems, Performant Python Programming, Computational Structures of Computer Graphics, and Data Structures and Algorithms."
      },
      {
        id: 4,
        year: "2019 - 2020",
        position: "Research Assistant",
        company: "Rhinoplasty Research Group, Florida Polytechnic University",
        details: "Conducted a survey of over fifty research papers, documenting the facial landmarks and measurements relevant to the field of rhinoplasty, under the guidance of Dr. Mazhar Celikoyar, Dr. Oguzhan Topsakal, and Dr. Mustafa Akbas. Collaborated with other students to develop a web interface for importing, exporting, and displaying feature point coordinates and measurements values onto 3D models of the human face."
      }
    ],
    educationExperience: [
      {
        id: 1,
        year: "2020 - Present",
        graduation: "Doctor of Philosophy in Computer Science",
        university: "University of Florida",
        details: ""
      },
      {
        id: 2,
        year: "2020 - 2023",
        graduation: "Master of Science in Computer Science",
        university: "University of Florida",
        details: null
      },
      {
        id: 3,
        year: "2018 - 2020",
        graduation: "Bachelor of Science in Computer Science",
        university: "Florida Polytechnic University",
        details: null
      },
      {
        id: 4,
        year: "2012 - 2016",
        graduation: "High School Diploma",
        university: "Archbishop McCarthy High School",
        details: null
      }
    ],
    coursework: [
      {
        id: 1,
        year: null,
        university: "Computer Graphics, Analysis of Algorithms, Advanced Data Structures, Computer Networks, 3D Audio, Advanced Computer Graphics, Math for Intelligent Systems, Distributed Operating Systems, Machine Learning, Advanced Machine Learning, Natural Language Processing",
        link: null
      },
    ],
    publications: [
      {
        id: 1,
        year: "2021",
        university: "Oguzhan Topsakal, Mustafa Ilhan Akbas, Bria Smith, Michael Perez, Ege Can Guden, Mazhar Mehmet Celikoyar. \"Evaluating the Reliability and Agreement of a Web-Based Facial Analysis Tool for Rhinoplasty\", International Journal of Computer Assisted Radiology and Surgery. 16, 1381–1391 (2021)",
        link: "https://link.springer.com/article/10.1007/s11548-021-02423-z"
      },
      {
        id: 2,
        year: "2021",
        university: "Mazhar Celikoyar, Michael Perez, Mustafa Ilhan Akbaş, Oguzhan Topsakal. \"Facial Surface Anthropometric Features and Measurements With an Emphasis on Rhinoplasty\", Aesthetic Surgery Journal (2021), ",
        link: "https://doi.org/10.1093/asj/sjab190"
      },
      {
        id: 3,
        year: "2021",
        university: "Oguzhan Topsakal, Mustafa Ilhan Akbas, Bria Smith, Michael Perez, Ege Can Guden, Mazhar Mehmet Celikoyar. \"Evaluating Intra and Inter Reliability of a Web-Based Facial Analysis Tool for Rhinoplasty,\" CARS 2021, Computer Assisted Radiology and Surgery (CARS) Conference (2021), ",
        link: "https://pubmed.ncbi.nlm.nih.gov/34146225/"
      },
      {
        id: 4,
        year: "2020",
        university: "Oguzhan Topsakal, Mustafa Ilhan Akbaş, Doga Demirel, Rafael Nunez, Bria Smith, Michael Perez, Mazhar Celikoyar. \"Digitizing rhinoplasty: a web application with threedimensional preoperative evaluation to assist rhinoplasty surgeons with surgical planning.\" Int J Comput Assist Radiol Surg. Nov;15(11):1941-1950. (2020)",
        link: "https://pubmed.ncbi.nlm.nih.gov/32888163/"
      },
      {
        id: 5,
        year: "2020",
        university: "Oguzhan Topsakal, Mustafa Ilhan Akbaş, Doga Demirel, Rafael Nunez, Bria Smith, Michael Perez, Mazhar Celikoyar. \"Digitizing Rhinoplasty: A web application for threedimensional preoperative planning\" In the Computer Assisted Radiology and Surgery (CARS) Congress (2020)",
        link: "https://www.cars-int.org/fileadmin/templates/downkoad/CARS2020_PreliminaryProgram_17032020.pdf"
      }
    ],
    unpublications: [
      {
        id: 0,
        year: "2023",
        university: "Perez, M., and Toler-Franklin, C. (2023). CNN-Based Action Recognition and Pose Estimation for Classifying Animal Behavior from Videos: A Survey. arXiv [preprint]. doi: 10.48550/arXiv.2301.06187",
        link: "https://arxiv.org/abs/2301.06187"
      },
      {
        id: 1,
        year: "2023",
        university: "Abstractive Urdu Summarization for News Article Dataset. Completed as a part of Natural Language Processing class. ",
        link: "",
        file: "/files/Urdu_Summarization_Report.pdf",
        filename: "Urdu Summarization Report"
      },
      {
        id: 2,
        year: "2022",
        university: "An Investigation of ADAM: A Stochastic Optimization Method. Completed as a part of Advanced Machine Learning class. ",
        link: "",
        file: "/files/ADAM_Investigation_Report.pdf",
        filename: "ADAM Investigation Report"
      },
      {
        id: 3,
        year: "2022",
        university: "Investigation of VideoGAN for Video Generation and Recognition. Completed as a part of Machine Learning class. ",
        link: "",
        file: "/files/VideoGAN_Investigation_Report.pdf",
        filename: "VideoGAN Investigation Report"
      },
      {
        id: 4,
        year: "2021",
        university: "From Here to There 3D Audio project. Completed as a part of 3D Audio class. ",
        link: "",
        file: "/files/Visual_3D_Audio_Project_Report.pdf",
        filename: "Visual 3D Audio Project Report"
      },
      {
        id: 5,
        year: "2020",
        university: "Survey of the 𝑘-Means Clustering Problem. Completed as a part of Analysis of Algorithms class.",
        link: "",
        file: "/files/k-means_Survey.pdf",
        filename: "k-means Clustering Survey"
      },
      
    ]
  },
  blogs: [
    
  ],
  contactInfo: {
    phoneNumbers: [''],
    emailAddress: [''],
    address: ""
  }
}


Mock.onGet("/api/information").reply(config => {
  const response = database.information;
  return [200, response];
});

Mock.onGet("/api/services").reply(config => {
  const response = database.services;
  return [200, response];
});

Mock.onGet("/api/reviews").reply(config => {
  const response = database.reviews;
  return [200, response];
});

Mock.onGet("/api/skills").reply(config => {
  const response = database.skills;
  return [200, response];
});

Mock.onGet("/api/portfolios").reply(config => {
  const response = database.portfolios;
  return [200, response];
});

Mock.onGet("/api/experience").reply(config => {
  const response = database.experience;
  return [200, response];
});

Mock.onGet("/api/blog").reply(config => {
  const response = database.blogs;
  return [200, response];
});

Mock.onGet("/api/contactinfo").reply(config => {
  const response = database.contactInfo;
  return [200, response];
});