import React, { useEffect, useState } from "react";

import {
  Nav,
  Bars,
  LinkScrolling,
} from "./NavbarElements";

//const ScrollLink = Scroll.ScrollLink

const Navbar = () => {
  return (
    <Nav>
      <Bars />
      <LinkScrolling 
            to="home" 
            spy={true} 
            smooth={true} 
            duration={500} 
            className='some-class' 
            activeClass='activeClass'
          >
        Home
      </LinkScrolling>
      <LinkScrolling 
      to="about" 
      spy={true} 
      smooth={true} 
      duration={500} 
      className='some-class' 
      activeClass='activeClass'
      >
        About
      </LinkScrolling>
      <LinkScrolling 
        to="resume" 
        spy={true} 
        smooth={true} 
        duration={500} 
        className='some-class' 
        activeClass='activeClass'
      >
        Resume
      </LinkScrolling>
      <LinkScrolling 
        to="portfolio" 
        spy={true} 
        smooth={true} 
        duration={500} 
        className='some-class' 
        activeClass='activeClass'
      >
        Portfolio
      </LinkScrolling>
    </Nav>
  );
};

export default Navbar;