import React, { useState } from "react";
import * as Icon from "react-feather";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.scss";
import Home from "./pages/Home";
import Notfound from "./pages/Notfound";

import Navbar from "./components/Navbar";

function App() {
  const [lightMode, setLightMode] = useState(false); // Made it true if you want to load your site light mode primary

  lightMode
    ? document.body.classList.add("light")
    : document.body.classList.remove("light");

  const handleMode = () => {
    if (!lightMode) {
      setLightMode(true);
      document.body.classList.add("light");
    } else {
      setLightMode(false);
      document.body.classList.remove("light");
    }
  };

  return (
      <BrowserRouter>
        <Navbar style={{position: "fixed", top: 0}}/>
        <Routes>
          <Route path="/" index element={<Home lightMode={lightMode} />} />
          <Route path="*" element={<Notfound />} />
        </Routes>
      </BrowserRouter>
  );
}

export default App;